/*
 * A pretty wrapper around the settings specified in .env file
 * that are accessed via process.env.REACT_APP_*
 */

export const config = {
    "stage": _(process.env.NODE_ENV, "production"),
    "version": _(process.env.REACT_APP_VERSION, "dev"),
    "backend": _(process.env.REACT_APP_BACKEND_URL, "http://localhost:8000"),
    "routing_backend": _(process.env.REACT_APP_ROUTING_BACKEND_URL, ""),
    "google_analytics_id": _(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, ""),
    "use_service_worker": _(b(process.env.REACT_APP_USE_SERVICE_WORKER), false),
    "session_token_key": _(process.env.REACT_APP_SESSION_TOKEN_KEY, "session_token"),
    "user_data_key": _(process.env.REACT_APP_USER_DATA_KEY, "user_data"),
    "locale_key": _(process.env.REACT_APP_LOCALE_KEY, "user_locale"),
    "featureflag_key": _(process.env.REACT_APP_FEATUREFLAG_KEY, "waffle"),
    "support_email": _(process.env.REACT_APP_SUPPORT_EMAIL, "support@opgo.dk")
}

type ConfigField = keyof typeof config
const optional_fields: ConfigField[] = [
    "google_analytics_id",
    "routing_backend",
]

// --------------------------------


function _<T>(x: T, default_value: NonNullable<T>) : NonNullable<T> {
    return (x)
        ? (x as NonNullable<T>)
        : default_value
}

function b(x: string | undefined): boolean | undefined {
    if (x === undefined) {
        return x
    }

    switch (x.toLowerCase()) {
    case "true": return true
    case "false": return false
    default: return undefined
    }
}

export class ConfigError extends Error {
}

export function check_config(): void {
    for (const [key, value] of Object.entries(config)) {
        const value_valid = value !== undefined && value !== ""
        if (!value_valid && !optional_fields.includes(key as ConfigField)) {
            throw new ConfigError("Misisng or invalid config value: " + key)
        }
    }
}