import * as React from "react"
import "./misc.css"

import { withTranslation, WithTranslation } from "react-i18next"

export interface CopyOnClickProps {
    title?: string
    text: string
}

export interface CopyOnClickState {
    message?: string
}

class CopyOnClick_ extends React.Component<CopyOnClickProps & WithTranslation, CopyOnClickState> {
    constructor(props: CopyOnClickProps & WithTranslation) {
        super(props)
        this.state = {}
    }

    copyToClipboard(event: React.FocusEvent<HTMLInputElement>) {
        const { t } = this.props

        const target = event.target as HTMLInputElement
        target.select()
        if (document.queryCommandEnabled("copy")) {
            document.execCommand("copy")
            this.setState({message: t("general.text_copied")})
            setTimeout(() => {
                this.setState({message: undefined})
            }, 2000)
        }
        target.focus()
    }

    render() {
        const { t } = this.props

        return (
            <div>
                <form>
                    {this.props.title ? (
                        <label>
                            {t(this.props.title)}
                            <input
                                type="text"
                                className="form-control copy-on-click read-only"
                                readOnly value={this.props.text}
                                onFocus={this.copyToClipboard.bind(this)}
                            />
                        </label>
                    ) : (
                        <input
                            type="text"
                            className="form-control copy-on-click read-only"
                            readOnly
                            value={this.props.text}
                            onFocus={this.copyToClipboard.bind(this)}
                        />
                    )}

                </form>
                {
                    this.state.message ? (
                        <div className="alert alert-success" role="alert">
                            {this.state.message}
                        </div>
                    ) : ("")
                }
            </div>
        )
    }

}

export const CopyOnClick = withTranslation()(CopyOnClick_)