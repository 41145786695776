const processArrayBuffer = (arrayBuffer: ArrayBuffer) => {
    let binary = ""
    const bytes = new Uint8Array(arrayBuffer)
    const length = bytes.byteLength
    for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return binary
}

export function readBinaryStringFromFile(
    file: File,
    callback: (file: File, data: string) => void
): void {
    console.log(file)
    const reader = new FileReader()
    reader.addEventListener("loadend", () => {
        callback(file, processArrayBuffer(reader.result as ArrayBuffer))
    })
    reader.readAsArrayBuffer(file)
}

export const asyncReadBinaryStringFromFile = async (
    file: File
): Promise<string> => {
    return processArrayBuffer(await file.arrayBuffer())
}

export const readUTF8StringFromFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener("loadend", () => {
            if (reader.result) {
                resolve(reader.result as string)
            } else {
                reject("Failed to read file")
            }
        })
        reader.readAsText(file, "UTF-8")
    })
}
