import * as React from "react"

import Dropzone, { FileRejection } from "react-dropzone"
import * as XLSX from "xlsx"

import { readBinaryStringFromFile } from "../../utility/readBinaryFromFile"



const excelFileFormat = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
const acceptedFileTypes: string = [excelFileFormat, ".csv"].join(", ")
// const acceptedFileTypesHuman = [".xlsx", ".csv"].join(", ")

type Props = Record<string, never>

export class SolverDisplayTest extends React.Component<Props> {

    onDrop(acceptedFiles: File[], rejectedFiles: FileRejection[]): void {
        if (acceptedFiles.length > 0) { // were any valid files dropped?

            acceptedFiles.forEach((file) => {
                if (file.type === excelFileFormat) { // Is is an xlsx file?
                    readBinaryStringFromFile(file, (file, content: string) => {

                        const workbook: XLSX.WorkBook = XLSX.read(content, { type: "binary" })

                        const sheet = workbook.Sheets[workbook.SheetNames[0]]

                        const html = XLSX.utils.sheet_to_csv(sheet)

                        console.log(html)

                    })

                } else {
                    console.log("Unknown")
                }
            })
        }

        if (rejectedFiles.length > 0) {
            console.log("Rejected files:", rejectedFiles)
        }
    }

    render() {
        return (
            <div>
                <Dropzone
                    key={"dropzone"}
                    accept={acceptedFileTypes}
                    onDrop={this.onDrop.bind(this)}
                >
                    {({ getRootProps, getInputProps }) => {
                        return (
                            <div {...getRootProps()} className="dropzone-center">
                                <input {...getInputProps()} />
                                <i className="fas fa-upload upload-icon"></i>
                            </div>
                        )
                    }}
                </Dropzone>
            </div>
        )
    }
}
