import * as React from "react"

// import { DatasheetSolverDisplay } from "./components/solverdisplay/datasheetsolver"

import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router"

import { Page404 } from "./App"
import { SolverDisplayTest } from "./components/solverdisplay"
import { ESPDValidatorDisplay } from "./components/solverdisplay/espd-validator"
import { useAbortController, useAxiosClient } from "./utility/API"
import { config } from "./utility/Config"
import { FullpageSpinner } from "./utility/FullpageSpinner"
import { Solver } from "./utility/Solver"
import { UserContext } from "./utility/UserManager"

const MapTestDisplay = React.lazy(
    () => import("./components/solverdisplay/map-test")
)
const MTPermitValidator = React.lazy(
    () => import("./components/solverdisplay/mt-permit-validator")
)
const MTSolverDisplay = React.lazy(
    () => import("./components/solverdisplay/mt/MTSolverDisplay")
)
const MTSolverDisplay2 = React.lazy(
    () => import("./components/solverdisplay/mt2/MTSolverDisplay2Function")
)
const NtSolverDisplay = React.lazy(
    () => import("./components/solverdisplay/nt/NTSolverDisplayFunction")
)

const CoreWrapper: React.FunctionComponent = () => {
    const params = useParams()

    return params.solver_id ? (
        <CoreFC solver_id={params.solver_id} />
    ) : (
        <Page404 />
    )
}

interface Props {
    solver_id: string
}

// interface State {
//     solver?: Solver
// }

const CoreFC: React.FC<Props> = (props) => {
    const { t } = useTranslation()

    const [solver, setSolver] = React.useState<Solver | undefined>(undefined)

    const { solver_id } = props

    const context = React.useContext(UserContext)

    const abortController = useAbortController()

    // Create Axios Client
    const axiosClient = useAxiosClient({
        abortController: abortController,
        timeout: 1000,
        sessionToken: context.sessionToken,
        retryOptions: {
            retries: 10000,
            retryDelay: (retryCount) => {
                return retryCount * 100
            },
        },
    })

    // Get solver data
    React.useEffect(() => {
        console.log("Getting solver data")
        axiosClient
            .get(`/solvers/${solver_id}/`)
            .then((response) => {
                console.log("Got solver data")
                setSolver(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [axiosClient, solver_id])

    const solver_name = React.useMemo(() => solver?.name, [solver])

    if (!solver) {
        return <FullpageSpinner />
    }

    switch (solver_name) {
    case "nt":
        return (
            <React.Suspense fallback={<FullpageSpinner />}>
                <NtSolverDisplay solverId={solver_id} />
            </React.Suspense>
        )
    case "mt":
        return (
            <React.Suspense fallback={<FullpageSpinner />}>
                <MTSolverDisplay solver_id={solver_id} />
            </React.Suspense>
        )
    case "mt2":
        return (
            <React.Suspense fallback={<FullpageSpinner />}>
                <MTSolverDisplay2 solver={solver} />
            </React.Suspense>
        )
    case "mt-solver-blank-vehno":
        return (
            <React.Suspense fallback={<FullpageSpinner />}>
                <MTSolverDisplay2 solver={solver} />
            </React.Suspense>
        )
    case "espd-validator":
        return (
            <React.Suspense fallback={<FullpageSpinner />}>
                <ESPDValidatorDisplay
                    solverId={solver_id}
                    solverName={solver_name}
                />
            </React.Suspense>
        )
    case "mt-validator-taxi":
        return (
            <React.Suspense fallback={<FullpageSpinner />}>
                <MTPermitValidator
                    solverId={solver_id}
                    solverName={solver_name}
                />
            </React.Suspense>
        )
    case "test":
        return <SolverDisplayTest />
    case "map-test":
        return <MapTestDisplay />
    default:
        // No valid solver display found
        return (
            <div className="text-center">
                <p>
                    {t("general.missing_module_for_solver", {
                        title: solver.title,
                    })}
                </p>
                <p>
                    <Trans i18nKey="general.contact_with_email">
                            Contact{" "}
                        <a href={`mailto:${config.support_email}`}>
                            {config.support_email}
                        </a>
                            .
                    </Trans>
                </p>
            </div>
        )
    }
}

// class CoreClassComponent
//     extends React.Component<Props & WithTranslation, State>
//     implements ActiveAJAXManager
// {
//     activeAJAX = []

//     constructor(props: Props & WithTranslation) {
//         super(props)

//         this.state = {
//             solver: undefined,
//         }
//     }

//     static contextType = UserContext
//     context!: React.ContextType<typeof UserContext>
//     // declare context: React.ContextType<typeof UserContext>

//     componentDidMount() {
//         activeAJAXManagerAdd(
//             this,
//             API.callWithToken(
//                 "GET",
//                 `/solvers/${this.props.solver_id}/`,
//                 this.context.sessionToken
//             )
//         )
//             .done((data) => {
//                 this.setState({
//                     solver: data,
//                 })
//             })
//             .fail((err) => {
//                 console.log(err)
//             })
//     }

//     componentWillUnmount() {
//         activeAJAXManagerUnmount(this)
//     }

//     render() {
//         const { t, solver_id } = this.props

//         if (!this.state.solver) {
//             return (
//                 <React.Fragment>
//                     <FullpageSpinner />
//                 </React.Fragment>
//             )
//         }

//         const solver = this.state.solver
//         const solver_name = solver.name.toLowerCase()

//         switch (solver_name) {
//         case "nt":
//             return (
//                 <React.Suspense fallback={<FullpageSpinner />}>
//                     <NtSolverDisplay solverId={this.props.solver_id} />
//                 </React.Suspense>
//             )
//         case "mt":
//             return (
//                 <React.Suspense fallback={<FullpageSpinner />}>
//                     <MTSolverDisplay solver_id={solver_id} />
//                 </React.Suspense>
//             )
//         case "mt2":
//             return (
//                 <React.Suspense fallback={<FullpageSpinner />}>
//                     <MTSolverDisplay2 solver={solver} />
//                 </React.Suspense>
//             )
//         case "mt-solver-blank-vehno":
//             return (
//                 <React.Suspense fallback={<FullpageSpinner />}>
//                     <MTSolverDisplay2 solver={solver} />
//                 </React.Suspense>
//             )
//         case "espd-validator":
//             return (
//                 <React.Suspense fallback={<FullpageSpinner />}>
//                     <ESPDValidatorDisplay
//                         solverId={solver_id}
//                         solverName={solver_name}
//                     />
//                 </React.Suspense>
//             )
//         case "mt-validator-taxi":
//             return (
//                 <React.Suspense fallback={<FullpageSpinner />}>
//                     <MTPermitValidator
//                         solverId={solver_id}
//                         solverName={solver_name}
//                     />
//                 </React.Suspense>
//             )
//         case "test":
//             return <SolverDisplayTest />
//         case "map-test":
//             return <MapTestDisplay />
//         default:
//             // No valid solver display found
//             return (
//                 <div className="text-center">
//                     <p>
//                         {t("general.missing_module_for_solver", {
//                             title: solver.title,
//                         })}
//                     </p>
//                     <p>
//                         <Trans i18nKey="general.contact_with_email">
//                                 Contact{" "}
//                             <a href={`mailto:${config.support_email}`}>
//                                 {config.support_email}
//                             </a>
//                                 .
//                         </Trans>
//                     </p>
//                 </div>
//             )
//         }
//     }
// }

export const Core = CoreWrapper
