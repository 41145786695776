// Custom route that checks if the user is logged in.
//
// https://stackoverflow.com/a/47476903

import * as React from "react"

import { Navigate } from "react-router-dom"

import { UserContext } from "./UserManager"

type Props = {
    readonly [x: string]: unknown
}

const PrivateRouteNew: React.FunctionComponent<Props> = (props) => {
    const user = React.useContext(UserContext)

    if (user.isLoggedIn()) {
        return <>{props.children}</>
    } else {
        return <Navigate to='/login' />
    }
}

export const PrivateRoute = PrivateRouteNew
export default PrivateRoute

// const PrivateRouteOld = ({ component: Component, ...rest }: any) => {
//     const navigate = useNavigate();

//     return (
//         <UserContext.Consumer>
//             {(user) => {
//                 lol(user.isLoggedIn()); return <Route
//                     {...rest}
//                     render={(props: any) =>
//                         user.isLoggedIn() ? (
//                             <Component {...props} />
//                         ) : lol(42) && navigate('/login', { state: { from: props.location } })}
//                 />
//             }}
//         </UserContext.Consumer>
//     );
// }

