import * as React from "react"

import { Navigate } from "react-router-dom"

import { api as API } from "../../utility/API"
import { UserContext } from "../../utility/UserManager"

type Props = Record<string, never>

interface State {
    loggedOut: boolean
}

export default class Logout extends React.Component<Props, State> {

    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    state: State = {
        loggedOut: false,
    }


    componentDidMount() {
        if (this.context.isLoggedIn()) {
            API.callWithToken("POST", "/auth/logout/", this.context.sessionToken).done((data) => {
                console.log(data)
                this.context.logout()
                this.setState({
                    loggedOut: true,
                }, () => {
                    this.forceUpdate()
                })
            }).fail((err) => {
                console.log(err)
            })
        } else {
            this.setState({loggedOut: true}, () => this.forceUpdate())
        }
    }

    shouldComponentUpdate() {
        return this.state.loggedOut
    }

    render() {
        return this.state.loggedOut ? (
            <Navigate to='/' />
        ) : (
            ""
        )
    }
}
