import * as React from "react"

import { OpgoSpinner } from "../components/misc/OpgoSpinner"

export const FullpageSpinner: React.FunctionComponent = () => {
    return (
        <div
            style={{ minHeight: "90vh" }}
            className="d-flex align-items-center justify-content-center"
        >
            <span className="align-middle">
                <OpgoSpinner
                    style={{ width: "5em", height: "auto" }}
                    progress="spin"
                />
            </span>
        </div>
    )
}
