import "./Login.css"
import * as React from "react"

import { Trans, withTranslation, WithTranslation } from "react-i18next"
import { Link, Navigate } from "react-router-dom"

import { config } from "../../utility/Config"
import { UserContext } from "../../utility/UserManager"




type LoginProps = Record<string, never>


interface LoginState {
    username: string
    password: string //TODO: consider alternative to keeping password as part of state
    error_message?: string
}



class Login extends React.Component<LoginProps & WithTranslation, LoginState> {
    timeout = 5

    constructor(props: LoginProps & WithTranslation) {
        super(props)

        this.state = {
            username: "",
            password: "",
            error_message: undefined
        }
    }

    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0
    }

    handleUsernameChange = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            username: elem.value
        })
    }

    handlePasswordChange = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            password: elem.value
        })
    }

    onLoginFailed(err: JQuery.jqXHR) {
        const data = JSON.parse(err.responseText)
        const message = data.non_field_errors[0]
        this.setState({
            error_message: message
        })
        setTimeout(() => {
            this.setState({
                error_message: ""
            })
        }, this.timeout * 1000)
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        this.context.login(
            this.state.username,
            this.state.password,
            this.onLoginFailed.bind(this)
        )
    }

    render() {
        const { t } = this.props

        return (
            <React.Fragment>
                <div className="Login">
                    <form onSubmit={this.handleSubmit}>
                        <h1>{t("user.login")}</h1>
                        <div className="form-group">
                            <label data-for="username">{t("user.username")}</label>
                            <input
                                id="username"
                                className="form-control"
                                autoFocus
                                value={this.state.username}
                                onChange={this.handleUsernameChange}
                                autoComplete="username"
                            />
                        </div>
                        <div className="form-group">
                            <label data-for="password">{t("user.password")}</label>
                            <input
                                id="password"
                                className="form-control"
                                type="password"
                                value={this.state.password}
                                onChange={this.handlePasswordChange}
                                autoComplete="current-password"
                            />
                            <Link to="/reset_password">{t("user.forgot_password_questionmark")}</Link>
                        </div>
                        <button
                            className="btn btn-primary btn-block"
                            disabled={!this.validateForm()}
                            type="submit"
                        >
                            {t("user.login")}
                        </button>
                        {
                            this.state.error_message && (
                                <div className="alert alert-danger">
                                    <span id="message">{this.state.error_message}</span>
                                </div>
                            )
                        }
                    </form>
                    {
                        this.context.isLoggedIn() && <Navigate to={"/"} />
                    }
                </div>
                <div className="fixed-bottom text-center text-muted">
                    <p>
                        <Trans i18nKey="general.any_issues_contact">
                            Kontakt <a href={`mailto:${config.support_email}`}>{config.support_email}</a>.
                        </Trans>
                    </p>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(Login)