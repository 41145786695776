import * as React from "react"

import { Trans, withTranslation, WithTranslation } from "react-i18next"
import { Link, Navigate } from "react-router-dom"

import { api } from "../utility/API"
import { config } from "../utility/Config"
import { FullpageSpinner } from "../utility/FullpageSpinner"
import { Solver } from "../utility/Solver"
import { UserContext } from "../utility/UserManager"


export type DashboardProps = Record<string, never>

export interface DashboardState {
    solversLoaded: boolean
    solvers: Solver[]
    activeAjax: JQueryXHR[]
}


class Dashboard_ extends React.Component<DashboardProps & WithTranslation, DashboardState> {

    constructor(props: DashboardProps & WithTranslation) {
        super(props)

        this.state = {
            solversLoaded: false,
            solvers: [],
            activeAjax: [],
        }

    }

    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    componentDidMount() {
        const getSolvers = api.callWithToken("GET", "/solvers/", this.context.sessionToken).done((data) => {
            this.setState({
                solversLoaded: true,
                solvers: data,
                activeAjax: this.state.activeAjax.filter((e) => e !== getSolvers)
            })
        }).fail((err) => {
            console.log(err)
            this.setState({
                activeAjax: this.state.activeAjax.filter((e) => e !== getSolvers),
                solversLoaded: true,
                solvers: [],
            })
        })
    }

    componentWillUnmount() {
        for (const xhr of this.state.activeAjax) {
            xhr.abort()
        }
    }

    render() {
        const { t } = this.props


        const solver_list = this.state.solvers.map((solver: Solver, index: number) => (
            <div id={solver.id} data-name={solver.name} key={index} className="card solver-card">
                <div className="card-body">
                    <h5 className="card-title">
                        {solver.title}
                        {this.context.isStaff && (<span className="text-muted">{" "}{solver.name}</span>)}
                    </h5>
                    <div className="float-right">
                        <Link to={`solve/${solver.id}`} data-goal="open-solver" className="btn btn-primary">{t("dashboard.start_computation")}</Link>
                    </div>
                </div>
            </div>
        ))

        const has_solvers = solver_list && solver_list.length && solver_list.length > 0

        const no_solvers = (
            <div className="text-center">
                <p>{t("dashboard.you_have_no_models")}</p>
                <p><Trans i18nKey="dashboard.contact">Hvis dette er en fejl, så tøv ikke med at kontakte <a href={`mailo:${config.support_email}`}>{config.support_email}</a>.</Trans></p>
                <p>{t("dashboard.problems_with_internet_explorer")}</p>
            </div>
        )

        if (solver_list.length === 1) {
            // Redirect to solver
            return <Navigate to={`solve/${this.state.solvers[0].id}`}/>
        }

        return (this.state.solversLoaded) ? (
            <div className="container">
                <div className="solvers">
                    {has_solvers ? (
                        <div>
                            {solver_list}
                        </div>
                    ) : no_solvers}
                </div>
            </div>
        ) : (
            <React.Fragment>
                <FullpageSpinner />
            </React.Fragment>
        )
    }
}

export const Dashboard = withTranslation()(Dashboard_)