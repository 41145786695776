import * as React from "react"

import update from "immutability-helper"

import { config } from "./Config"
import { FullpageSpinner } from "./FullpageSpinner"
import { advUnmount, advAdd, advActiveAJAXManager } from "./Misc"


/**
 * Set a browser cookie
 * @param cname cookie identifier
 * @param cvalue value of cookie
 * @param exhours cookie lifetime in hours
 */
function setCookie(cname: string, cvalue: string, exhours: number) {
    const d = new Date()
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000))
    const expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

/**
 * Get the value associated with a browser cookie.
 * @param cname cookie identifier
 */
function getCookie(cname: string) {
    const name = cname + "="
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

/**
 * Deletes a cookie
 * @param cname Name of cookie to delete
 */
function deleteCookie(cname: string) {
    setCookie(cname, "", 0)
}

const session_token_key = config.session_token_key


/** WARN: Do not trust the value of isStaff as it could be modified by the user */
export interface userContext {
    name: string
    email: string
    sessionToken: string
    isStaff: boolean
    login: (user: string, pass: string, onFail?: (data: JQuery.jqXHR) => void) => void
    logout: () => void
    isLoggedIn: () => boolean
}

type Props = {
    children?: React.ReactNode
}

interface State {
    currentContext: userContext
    loading: boolean
}

/**
 * Context provider component providing simple access to various
 * user information. Handles session tokens
 */
export class UserManager extends React.Component<Props, State> implements advActiveAJAXManager {
    /**
     * * Attempt to login with existing token from cookie
     * * Get staff status
     */
    activeAJAX = new Map()
    constructor(props: Props) {
        super(props)
        this.state = {
            currentContext: UserManager.defaultContext,
            loading: true
        }
    }

    static defaultContext: userContext = {
        name: "",
        email: "",
        sessionToken: "",
        isStaff: false,
        login: () => { console.warn("login not defined") },
        logout: () => { console.warn("logout not defined") },
        isLoggedIn: () => false,
    }

    /**
     * Convert state to userContext
     */
    getContextFromState(): userContext {
        return {
            name: this.state.currentContext.name,
            email: this.state.currentContext.email,
            sessionToken: this.state.currentContext.sessionToken,
            isStaff: this.state.currentContext.isStaff,
            login: this.login.bind(this),
            logout: this.logout.bind(this),
            isLoggedIn: this.isLoggedIn.bind(this),
        }
    }

    verifyToken(token: string): void {
        advAdd(this, "user", "GET", "/is_user", token, { async: false }).done(() => {
            this.setState((state) => update(state, {
                currentContext: {
                    sessionToken: { $set: token }
                }
            }))
            this.getUserData(true)
        }).fail((res) => {
            if (res.status === 403) {
                this.setState((state) => update(state, {
                    currentContext: {
                        $set: UserManager.defaultContext
                    },
                    loading: {$set: false}
                }))
            }
        })
    }

    getUserData(waitForToken: boolean): void {
        if (waitForToken && this.state.currentContext.sessionToken === UserManager.defaultContext.sessionToken) {
            setTimeout(() => this.getUserData(true), 50)
            return
        }
        advAdd(this, "user", "GET", "/get_user_data", this.state.currentContext.sessionToken, {beforeSend: () => {this.setState({loading:true})}}).done((data) => {
            this.setState((state) => update(state,
                {
                    currentContext: {
                        name: { $set: data.username },
                        email: { $set: data.email },
                        isStaff: { $set: data.is_staff }
                    },
                    loading: {$set: false}
                }))
        }).fail((response) => {
            console.log(response)
        })
    }

    getIsStaff(): void {
        const { sessionToken } = this.state.currentContext
        advAdd(this, "user", "GET", "/get_user_is_staff", sessionToken).done((response) => {
            if (response.is_staff === true) {
                this.setState((state) => update(state, {
                    currentContext: {
                        isStaff: { $set: true }
                    }
                }))
            } else {
                this.setState((state) => update(state, {
                    currentContext: {
                        isStaff: { $set: false }
                    }
                }))
            }
        }).fail(() => {
            this.setState((state) => update(state, {
                currentContext: {
                    isStaff: { $set: false }
                }
            }))
        })
    }

    registerToken(token: string): void {
        setCookie(session_token_key, token, 10)
        this.setState((state) => update(state,
            {
                currentContext: {
                    sessionToken: { $set: token }
                }
            }
        ))
        this.getUserData(true)
    }

    renewToken(): void { // TODO
    }

    login(username: string, password: string, onFail?: (data: JQueryXHR) => void): void {
        advAdd(this, "guest", "POST", "/auth/login/", undefined, {
            data: {
                username: username,
                password: password,
            }
        }).done(
            (data) => {
                console.log("Login response: " + data)
                this.registerToken(data.token)
            }
        ).fail(
            (data) => onFail && onFail(data)
        )
    }

    logout(): void {
        this.setState((state) => update(state, {
            currentContext: {
                $set: UserManager.defaultContext
            }
        }))
        deleteCookie(session_token_key)
        this.forceUpdate()
    }

    isLoggedIn(): boolean {
        return !!this.state.currentContext.name || !!this.state.currentContext.email
    }

    componentDidMount() {
        const token = getCookie(session_token_key)
        if (token) {
            this.verifyToken(token)
        } else {
            this.setState({loading: false})
        }
    }

    componentWillUnmount() {
        advUnmount(this)
        // Cleanup of timers/hooks/etc
    }

    render() {
        return this.state.loading ? (
            <FullpageSpinner/>
        ) : (
            <UserContext.Provider value={this.getContextFromState()}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}


export const UserContext = React.createContext(UserManager.defaultContext)
