import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { default as resourcesToBackend } from "i18next-resources-to-backend"
import { initReactI18next } from "react-i18next"

// import { resources } from "./resources/translations"

const local_lang = localStorage.getItem("lang")
if (local_lang === "dk") {
    localStorage.setItem("lang", "da")
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(
        resourcesToBackend((language, namespace, callback) => {
            import(`./resources/locales/${language}.json`)
                .then((resources) => {
                    callback(null, resources)
                })
                .catch((err) => {
                    callback(err, null)
                })
        })
    )
    .init({
        // resources,
        fallbackLng: "en",

        detection: {
            order: [
                "localStorage",
                "querystring",
                "cookie",
                "sessionStorage",
                "navigator",
                "htmlTag",
                "path",
                "subdomain",
            ],
            lookupLocalStorage: "lang",
            lookupCookie: "django_language",
        },

        interpolation: {
            escapeValue: false, // Not reaeded in react
        },
    })

export default i18n
