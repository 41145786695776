import * as React from "react"

import { withTranslation, WithTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import {CopyOnClick} from "../../components/misc/CopyOnClick"
import {api as API} from "../../utility/API"
import { UserContext } from "../../utility/UserManager"


interface TokenInfo {
    user: number
    digest: string
    created: Date // Date.parse of the string
    expires: Date // Date.parse of the string
}

type Props = Record<string, never>
interface State {
    tokens: TokenInfo[]
    tokensLoaded: boolean
}


class Settings_ extends React.Component<Props & WithTranslation, State> {

    constructor(props: Props & WithTranslation) {
        super(props)

        this.state = { tokens: [], tokensLoaded: false }

    }

    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    getLoggedIn() {
        return null
    }

    componentDidMount() {
        API.callWithToken("GET", "/get_tokens", this.context.sessionToken).done(
            (data) => {
                const tokenList: TokenInfo[] = []
                for (const token of data) {
                    tokenList.push({
                        user: token["user"],
                        digest: token["digest"],
                        created: new Date(token["created"]),
                        expires: new Date(token["expires"])
                    })
                }
                this.setState({ tokens: tokenList, tokensLoaded: true })
            }).fail(
            (err) => {
                console.log(err)
            }
        )
    }

    render() {

        const { t } = this.props

        //        const tokens_loaded = this.state && this.state.tokens && this.state.tokens.length > 0;

        const options = { 
            weekday: "long", 
            year: "numeric", 
            month: "long", 
            day: "numeric", 
            hour: "numeric", 
            minute: "numeric"
        } as const

        return (
            <div>
                <div className="container">
                    <h1>{t("user.settings")}</h1>
                    <div className="col">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>{t("user.username")}</th>
                                    <td>{this.context.name}</td>
                                </tr>
                                <tr>
                                    <th>{t("user.email")}</th>
                                    <td>{this.context.email}</td>
                                </tr>
                                <tr>
                                    <th>{t("user.password")}</th>
                                    <td>
                                        <Link to="/change_password">
                                            <button type="button" className="btn btn-primary">
                                                {t("user.change_action")}
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t("user.api_key")}</th>
                                    <td>
                                        <CopyOnClick text={this.context.sessionToken}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.state.tokensLoaded &&
                        (<div className="col">
                            <h2>{t("user.session.title")}</h2>
                            <div className='session-table'>
                                <table className="table">
                                    <caption>
                                        <Link to='/logout_all'>{t("user.logout_all_active_sessions")}</Link>
                                    </caption>
                                    <tbody>
                                        <tr>
                                            <th>{t("user.session.digest")}</th>
                                            <th>{t("user.session.created")}</th>
                                            <th>{t("user.session.expires")}</th>
                                        </tr>
                                        {
                                            this.state.tokens.map((token) => {
                                                return (<tr key={"row-" + token.digest}>
                                                    <td key={"digest-" + token.digest}>{token.digest.slice(0, 10)}</td>
                                                    <td key={"created-" + token.digest}>{token.created.toLocaleDateString("da-DK", options)}</td>
                                                    <td key={"expires-" + token.digest}>{token.expires.toLocaleDateString("da-DK", options)}</td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        )
    }
}

export const Settings = withTranslation()(Settings_)