import * as React from "react"

import Moment from "react-moment"

import {api} from "../utility/API"
import { FullpageSpinner } from "../utility/FullpageSpinner"
import {UserContext} from "../utility/UserManager"

export type Log = {
    readonly username: string
    readonly email: string
    readonly first_name: string
    readonly last_name: string
    readonly logs: ReadonlyArray<{
        readonly id: number
        readonly requested_at: string
        readonly path: string
    }>
}

type Props = Record<string, never>

interface State {
    logs: Array<Log> | null
}


function pathToSolverId(path: string) {
    return path.split("/")[2]
}

const UserShowSolveCalls: React.FunctionComponent<{ user: Log }> = (props) => {
    const { user } = props
    const name = user.first_name ? (user.first_name + " " + user.last_name) : user.username

    const latest = user.logs.slice(-1)[0]

    if (!latest) {
        return null
    }

    return (
        <div className="card">
            <div className="card-body">
                <h3>{name}</h3>

                <br/>

                <table>
                    <tbody>
                        <tr>
                            <td>Number of solve calls:</td>
                            <td>{user.logs.length}</td>
                        </tr>
                        <tr>
                            <td>Latest solve call:</td>
                            <td title={latest.requested_at}><Moment fromNow>{latest.requested_at}</Moment></td>
                        </tr>
                    </tbody>
                </table>

                <br/>
                <br/>

                <table className="table">
                    <thead>
                        <th>#</th>
                        <th>Solver</th>
                        <th>Time</th>
                    </thead>
                    <tbody>
                        {user.logs.slice(-5).map((log, index) => (
                            <tr key={index}>
                                <td>{log.id}</td>
                                <td>{pathToSolverId(log.path)}</td>
                                <td><Moment>{log.requested_at}</Moment></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}


export class AdminDashboard extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            logs: null,
        }

    }

    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    componentDidMount() {
        api.callWithToken("GET", "/customers", this.context.sessionToken).done((data) => {
            this.setState({
                logs: data.logs,
            })
        }).fail((err) => {
            console.error(err)
        })
    }

    render() {

        if (!this.state.logs) {
            return (
                <React.Fragment>
                    <FullpageSpinner />
                </React.Fragment>
            )
        }

        return (
            <div className="container">
                <br />
                <h1>Admin Dashboard</h1>

                <h2>Solve Calls</h2>

                <div>
                    {Object.values(this.state.logs).map((log, index) => (
                        <UserShowSolveCalls key={index} user={log} />
                    ))}
                </div>
            </div>
        )
    }
}
