import "./Login.css"

import * as React from "react"

import { Trans, withTranslation, WithTranslation } from "react-i18next"
import { Link, Navigate } from "react-router-dom"


import { api as API } from "../../utility/API"
import { config } from "../../utility/Config"
import { UserContext } from "../../utility/UserManager"


export type ResetPasswordProps = Record<string, never>
export interface ResetPasswordState {
    email: string
    error_message?: string
    reset_requested: boolean
}

class ResetPassword extends React.Component<ResetPasswordProps & WithTranslation, ResetPasswordState> {
    timeout = 5

    constructor(props: ResetPasswordProps & WithTranslation) {
        super(props)

        this.state = {
            email: "",
            error_message: undefined,
            reset_requested: false
        }
    }

    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    validateForm() {
        return this.state.email.length > 0
    }

    handleEmailChange = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            email: elem.value
        })
    }

    handleSubmit = (event: React.FormEvent<EventTarget>) => {
        event.preventDefault()

        API.callWithToken("POST", "/rest-auth/password/reset/", this.context.sessionToken, {
            data: {
                email: this.state.email,
            }
        }).done((data) => {
            console.log(data)
            this.setState({
                reset_requested: true
            })
        }).fail((err) => {
            //const data = JSON.parse(err.responseText);
            this.setState({
                error_message: err.responseText
            })

            setTimeout(() => {
                this.setState({
                    error_message: undefined
                })
            }, this.timeout * 1000)
        })
    }




    render() {
        const { t } = this.props

        return (
            <React.Fragment>
                <div className="Login">
                    <form onSubmit={this.handleSubmit}>
                        <div className="mt-3">
                            <Link to="/login"><span aria-hidden="true">&larr;</span> {t("user.login")}</Link><br/>
                            <h1 className="mt-0">
                                {t("user.forgot_password")}
                            </h1>
                        </div>
                        <div className="control-group" data-controlId="email">
                            <label data-for="email">{t("user.email")}</label>
                            <input
                                id="email"
                                className="form-control"
                                autoFocus
                                type="email"
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                            />
                        </div>
                        <br/>
                        <button
                            className="btn btn-primary btn-block"
                            disabled={!this.validateForm()}
                            type="submit"
                        >
                            {t("user.ask_for_new_password")}
                        </button>
                    </form>
                    {
                        this.state.reset_requested ? <Navigate to='/reset_password/confirm' /> : ""
                    }
                    {
                        this.state.error_message ? (
                            <div className="text-center">
                                <br />
                                <span id="message" className="rejected-file">{this.state.error_message}</span>
                            </div>
                        ) : ""
                    }
                </div>
                <div className="fixed-bottom text-center text-muted">
                    <p>
                        <Trans i18nKey="general.any_issues_contact">
                            Kontakt <a href={`mailto:${config.support_email}`}>{config.support_email}</a>.
                        </Trans>
                    </p>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(ResetPassword)