import "./Login.css"
import * as React from "react"

import { withTranslation, WithTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"

import { api as API } from "../../utility/API"
import { UserContext } from "../../utility/UserManager"


export type ChangePasswordProps = Record<string, never>
export interface ChangePasswordState {
    old_password: string
    new_password1: string
    new_password2: string
    error_message?: string
    password_changed: boolean
}

class ChangePassword extends React.Component<ChangePasswordProps & WithTranslation, ChangePasswordState> {
    timeout = 5

    constructor(props: ChangePasswordProps & WithTranslation) {
        super(props)

        this.state = {
            old_password: "",
            new_password1: "",
            new_password2: "",
            error_message: undefined,
            password_changed: false
        }
    }

    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    validateForm() {
        return this.state.old_password.length > 0
            && this.state.new_password1.length > 0
            && this.state.new_password2.length > 0
    }

    handleOldPasswordChange = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            old_password: elem.value
        })
    }

    handleNewPassword1Change = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            new_password1: elem.value
        })
    }

    handleNewPassword2Change = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            new_password2: elem.value
        })
    }


    handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()

        API.callWithToken("POST", "/rest-auth/password/change/", this.context.sessionToken, {
            data: {
                old_password: this.state.old_password,
                new_password1: this.state.new_password1,
                new_password2: this.state.new_password2
            }
        }).done(() => {
            this.setState({
                password_changed: true
            })
        }).fail((err) => {
            //const data = JSON.parse(err.responseText);
            this.setState({
                error_message: err.responseText
            })

            setTimeout(() => {
                this.setState({
                    error_message: undefined
                })
            }, this.timeout * 1000)
        })
    }




    render() {
        const { t } = this.props

        if (this.context.name === "demo") {
            return (
                <div className="text-center">
                    <h3>{t("user.cannot_change_password_for_demo_user")}</h3>
                </div>
            )
        }

        return (
            <div className="Login">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group" data-controlId="old_password">
                        <label data-for="old-password">{t("user.current_password")}</label>
                        <input
                            id="old-password"
                            className="form-control"
                            autoFocus
                            type="password"
                            value={this.state.old_password}
                            onChange={this.handleOldPasswordChange}
                        />
                    </div>
                    <div className="form-group" data-controlId="new_password1">
                        <label data-for="new-password-1">{t("user.new_password")}</label>
                        <input
                            id="new-password-1"
                            className="form-control"
                            type="password"
                            value={this.state.new_password1}
                            onChange={this.handleNewPassword1Change}
                        />
                    </div>
                    <div className="form-group" data-controlId="new_password2">
                        <label data-for="new-password-2">{t("user.repeat_new_password")}</label>
                        <input
                            id="new-password-2"
                            className="form-control"
                            type="password"
                            value={this.state.new_password2}
                            onChange={this.handleNewPassword2Change}
                        />
                    </div>
                    <button
                        className="btn btn-primary btn-block"
                        disabled={!this.validateForm()}
                        type="submit"
                    >
                        {t("user.change_password")}
                    </button>
                </form>
                {
                    this.state.password_changed && <Navigate to='/logout_all' />
                }
                {
                    this.state.error_message ? (
                        <div className="text-center">
                            <br />
                            <span id="message" className="rejected-file">{this.state.error_message}</span>
                        </div>
                    ) : ""
                }
            </div>
        )
    }
}

export default withTranslation()(ChangePassword)