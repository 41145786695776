import React from "react"

import ReactFlagsSelect from "react-flags-select"
import { withTranslation, WithTranslation } from "react-i18next"
import { FaStar, FaWrench } from "react-icons/fa"
import { Link } from "react-router-dom"

import local from "./utility/LocalStorageHelper"
import { UserContext } from "./utility/UserManager"




interface Props {
    backendAlive?: boolean
}

interface State {
    isOpen: boolean
}

const languages = [
    {
        flag: "GB",
        lang: "en",
        code: "en-GB",
    }, {
        flag: "DK",
        lang: "da",
        code: "da-DK",
    }, {
        flag: "DE",
        lang: "de",
        code: "de-DE",
    },
]

class OurNavbar_ extends React.Component<Props & WithTranslation, State> {
    static defaultProps: Props

    constructor(props: Props & WithTranslation) {
        super(props)

        this.toggle = this.toggle.bind(this)
        this.state = {
            isOpen: false
        }
    }

    static contextType = UserContext
    context!: React.ContextType<typeof UserContext>

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    reset() {
        local.reset()
        window.location.reload()
    }

    onLanguageSelect(flag: string): void {
        const { i18n } = this.props

        // Fallback, should not be needed
        let code = "en-US"

        const lang = languages.find((l) => l.flag === flag)

        if (lang) {
            code = lang.code
        }

        i18n.changeLanguage(code)
    }

    getLanguageForSelect(): string {
        const { i18n } = this.props

        const [lang,] = i18n.language.split("-")

        const language = languages.find((l) => l.lang === lang)

        if (language) {
            return language.flag
        }

        // FAllback to english
        return "GB"
    }

    render() {
        const { t } = this.props

        const language_options = languages.map((l) => l.flag)

        const user = {
            is_staff: this.context.isStaff,
            email: this.context.email,
            username: this.context.name,
            isLoggedIn: this.context.isLoggedIn
        }

        const navigation_list = [
            ["/", t("general.home")],
            ["/help", t("general.help_planned_features")],
        ]

        const navigation = navigation_list.map(([route, title], index) => (
            <li className="nav-item" key={index}>
                <Link className="nav-link" to={route}>{title}</Link>
            </li>
        ))

        const user_dropdown = user.isLoggedIn() ? (
            <div id="logged-in" className="nav-item dropdown">
                <button className="nav-link btn btn-link dropdown-toggle"
                    id="navbarDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    {user.is_staff ? (<FaStar className="fa-spin" />) : ""} {user.email ? user.email : user.username}
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/settings">{t("user.settings")}</Link>
                    <Link className="dropdown-item" to="#" onClick={this.reset.bind(this)}>{t("user.reset")}</Link>
                    {
                        user.is_staff && (
                            <React.Fragment>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to="/admin">
                                    <span>{t("user.admin_panel")}&nbsp;<FaWrench /></span>
                                </Link>
                                <Link className="dropdown-item" to="/admin-dashboard">
                                    <span>{t("user.dashboard")}&nbsp;<FaWrench /></span>
                                </Link>
                            </React.Fragment>
                        )
                    }


                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" id="navbar-logout-link" to="/logout">{t("user.logout")}</Link>
                </div>
            </div>
        ) : (
            <React.Fragment />
        )

        const brand_style = {
            display: "flex",
            alignItems: "center"
        }

        const logo_style = {
            height: "1.1em",
            paddingRight: "0.6em"
        }

        return (
            <nav id="navbar" className="navbar navbar-expand-md navbar-light bg-light sticky-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/" style={brand_style}>
                        <img src="/assets/logo.svg" style={logo_style} alt="OpGo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggle} >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            {user.isLoggedIn() ? navigation : null}
                        </ul>
                        <div className="navbar-nav ml-auto">
                            {user_dropdown}
                            <ReactFlagsSelect
                                selected={this.getLanguageForSelect()}
                                countries={language_options}
                                showSelectedLabel={false}
                                showOptionLabel={false}
                                onSelect={this.onLanguageSelect.bind(this)}
                                className="language-select"
                            />
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export const OurNavbar = withTranslation()(OurNavbar_)

OurNavbar.defaultProps = {
    backendAlive: true,
}
