import "./App.css"
import * as React from "react"

import { createBrowserHistory } from "history"
import * as ReactGA from "react-ga"
import { withTranslation, WithTranslation, Trans, useTranslation } from "react-i18next"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { AdminDashboard } from "./components/AdminDashboard"
import { Dashboard } from "./components/Dashboard"
import { SolverInfo } from "./components/SolverInfoFunctional"
import ChangePassword from "./components/user/ChangePassword"
import Login from "./components/user/Login"
import Logout from "./components/user/Logout"
import LogoutAll from "./components/user/LogoutAll"
import ResetPassword from "./components/user/ResetPassword"
import ResetPasswordConfirm from "./components/user/ResetPasswordConfirm"
import { Settings } from "./components/user/Settings"
import { Core } from "./CoreFunctional"
import { Help } from "./Help"
import { OurNavbar } from "./OurNavbar"
import { api as API } from "./utility/API"
import { config } from "./utility/Config"
import { FullpageSpinner } from "./utility/FullpageSpinner"
import * as Misc from "./utility/Misc"
import { PrivateRoute } from "./utility/PrivateRoute"
import { UserManager } from "./utility/UserManager"




const history = createBrowserHistory()

history.listen((location) => {
    if (config.google_analytics_id) {
        ReactGA.pageview(location.location.pathname)
    }
})


export const Page404: React.FunctionComponent = () => (
    <div className="text-center">
        <h2>404</h2>
        <h3><Trans i18nKey="app.no_pages_match">Ingen sider der matcher <code>{window.location.pathname}</code></Trans></h3>
    </div>
)


const BackendLostConnection = () => {
    const { t } = useTranslation()

    return (
        <div id="backend-lost-connection-banner">
            {t("app.lost_connection_to_server")}
        </div>
    )
}


function RedirectToAdmin() {
    window.location.assign(`${config.backend}/admin`)
    return <FullpageSpinner />
}

type Props = Record<string, never>

interface State {
    backendAlive: boolean
    intervalId: number
    loggedOutModal: boolean
    newVersion?: string
}

// TODO: Functional component!
class App extends React.Component<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props)

        this.aliveChecker = this.aliveChecker.bind(this)

        this.state = {
            backendAlive: true, // Assume it is alive
            intervalId: 0,
            loggedOutModal: false,
        }
    }


    componentDidMount() {
        const interval_time = 5
        const intervalId = window.setInterval(this.aliveChecker, interval_time * 1000)

        // store intervalId in the state so it can be accessed later:
        this.setState({
            intervalId: intervalId
        })

        this.aliveChecker()
        Misc.initTooltips()
    }

    componentDidUpdate() {
        // this.aliveChecker();
        Misc.initTooltips()
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId)
        console.log("App component updated.")
    }

    aliveChecker() { // TODO: --> Component
        const setTo = (value: boolean) => () => {
            if (this.state.backendAlive !== value) {
                this.setState({
                    backendAlive: value
                })
            }
        }

        API.alive().done(setTo(true)).fail((err) => {
            if (err.status === 401 || err.status === 403) {
                this.showLoggedOutModal()
                return
            }
            if (err.status === 418) {
                this.showUpdateModal(err.responseJSON["frontend_min_version"])
                return
            }
            setTo(false)()
        })
    }

    showLoggedOutModal() {
        this.setState({
            loggedOutModal: true,
        })
    }

    redirectToLogin() {
        this.setState({
            loggedOutModal: false,
        })

        window.location.reload()
    }

    showUpdateModal(new_version: string) {
        this.setState({
            newVersion: new_version,
        })
    }

    forceRefresh() {
        this.setState({
            newVersion: undefined,
        })

        window.location.reload()
    }



    render() {
        const { t } = this.props

        return (
            <UserManager>
                <BrowserRouter>
                    <div className="App">

                        {!this.state.backendAlive && (
                            <BackendLostConnection />
                        )}
                        <OurNavbar />
                        <Routes>
                            {/* Public */}
                            <Route path="/login" element={<Login />} />
                            <Route path="/reset_password" element={<ResetPassword />} />
                            <Route path="/reset_password/confirm" element={<ResetPasswordConfirm />} />
                            <Route path="/help" element={<Help />} />
                            <Route path="/admin" element={<RedirectToAdmin />} />
                            <Route element={<Page404 />} />

                            {/* Private */}
                            <Route path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>}/>
                            <Route path="/logout_all" element={<PrivateRoute><LogoutAll /></PrivateRoute>} />
                            <Route path="/change_password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
                            <Route path="/Settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                            <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                            <Route path="/solve/:solver_id" element={<PrivateRoute><React.Suspense fallback={<FullpageSpinner />} ><Core /></React.Suspense></PrivateRoute>} />
                            <Route path="/solver_info/:solver_id" element={<PrivateRoute><SolverInfo /></PrivateRoute>} />
                            <Route path="/admin-dashboard" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />

                        </Routes>

                        <div className="modal fade" id="modalLoggedOut" data-tabindex="-1" data-show={this.state.loggedOutModal} role="dialog" data-backdrop="static">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{t("app.logged_out")}</h5>
                                    </div>
                                    <div className="modal-body">
                                        {t("app.your_session_has_expired")}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={this.redirectToLogin.bind(this)}>
                                            {t("app.go_to_login")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {Misc.modalHack("#modalLoggedOut", this.state.loggedOutModal)}

                        <div className="modal fade" id="modalNewVersion" data-tabindex="-1" data-show={!!this.state.newVersion} role="dialog" data-backdrop="static">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{t("app.noun_update")}</h5>
                                    </div>
                                    <div className="modal-body">
                                        {t("app.theres_is_an_update")}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={this.forceRefresh.bind(this)}>
                                            {t("app.verb_update")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {Misc.modalHack("#modalNewVersion", !!this.state.newVersion)}

                    </div>
                </BrowserRouter>
            </UserManager>
        )
    }
}

export default withTranslation()(App)
