import "./Login.css"

import * as React from "react"

import { withTranslation, WithTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"


import {api as API} from "../../utility/API"


export type ResetPasswordConfirmProps = Record<string, never>
export interface ResetPasswordConfirmState {
    uid: string
    token: string
    new_password1: string
    new_password2: string
    error_message?: string
    reset_complete: boolean
}

class ResetPasswordConfirm extends React.Component<ResetPasswordConfirmProps & WithTranslation, ResetPasswordConfirmState> {
    timeout = 5

    constructor(props: ResetPasswordConfirmProps & WithTranslation) {
        super(props)

        this.state = {
            uid: "",
            token: "",
            new_password1: "",
            new_password2: "",
            error_message: undefined,
            reset_complete: false
        }
    }

    validateForm() {
        return this.state.uid.length > 0
            && this.state.token.length > 0
            && this.state.new_password1.length > 0
            && this.state.new_password2.length > 0
    }

    handleUidChange = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            uid: elem.value
        })
    }

    handleTokenChange = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            token: elem.value
        })
    }

    handleNewPassword1Change = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            new_password1: elem.value
        })
    }

    handleNewPassword2Change = (event: React.ChangeEvent<EventTarget>) => {
        const elem = event.target as HTMLInputElement
        this.setState({
            new_password2: elem.value
        })
    }

    handleSubmit = (event: React.FormEvent<EventTarget>) => {
        event.preventDefault()

        API.callAsGuest("POST", "/rest-auth/password/reset/confirm/", {
            data: {
                uid: this.state.uid,
                token: this.state.token,
                new_password1: this.state.new_password1,
                new_password2: this.state.new_password2,
            }
        }).done((data) => {
            console.log(data)
            this.setState({
                reset_complete: true
            })
        }).fail((err) => {
            //const data = JSON.parse(err.responseText);
            this.setState({
                error_message: err.responseText
            })

            setTimeout(() => {
                this.setState({
                    error_message: undefined
                })
            }, this.timeout * 1000)
        })
    }




    render() {
        const { t } = this.props

        return (
            <div className="Login">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group" data-controlId="uid">
                        <label data-for="uid">{t("user.uid")}</label>
                        <input
                            id="uid"
                            className="form-control"
                            autoFocus
                            type="text"
                            value={this.state.uid}
                            onChange={this.handleUidChange}
                        />
                    </div>
                    <div className="form-group" data-controlId="token">
                        <label data-for="token">{t("user.token")}</label>
                        <input
                            id="token"
                            className="form-control"
                            type="text"
                            value={this.state.token}
                            onChange={this.handleTokenChange}
                        />
                    </div>
                    <div className="form-group" data-controlId="new_password1">
                        <label data-for="new_password1">{t("user.new_password")}</label>
                        <input
                            id="new_password1"
                            className="form-control"
                            type="password"
                            value={this.state.new_password1}
                            onChange={this.handleNewPassword1Change}
                        />
                    </div>
                    <div className="form-group" data-controlId="new_password2">
                        <label data-for="new_password2">{t("user.repeat_new_password")}</label>
                        <input
                            id="new_password2"
                            className="form-control"
                            type="password"
                            value={this.state.new_password2}
                            onChange={this.handleNewPassword2Change}
                        />
                    </div>
                    <button
                        className="btn btn-primary btn-block"
                        disabled={!this.validateForm()}
                        type="submit"
                    >
                        {t("user.reset_password")}
                    </button>
                </form>
                {
                    this.state.reset_complete ? <Navigate to='/' /> : ""
                }
                {
                    this.state.error_message &&
                        <div className="text-center">
                            <br />
                            <span id="message" className="rejected-file">{this.state.error_message}</span>
                        </div>
                }
            </div>
        )
    }
}

export default withTranslation()(ResetPasswordConfirm)