import * as React from "react"

import { /*DatasheetSolverDisplay, DatasheetSolverDisplayMT*/ } from "./datasheetsolver"

import { Trans, withTranslation, WithTranslation } from "react-i18next"

import {config} from "../../utility/Config"



export interface SolverDisplayProps {
    solver_id: string
}

class SolverDisplay_ extends React.Component<SolverDisplayProps & WithTranslation> {

    render() {
        const { t } = this.props

        if (this.props.solver_id.toLowerCase() === "nt") {
            //return (<DatasheetSolverDisplay solver_id={this.props.solver_id} />)
        } else if (this.props.solver_id.toLowerCase() === "mt") {
            //return (<DatasheetSolverDisplayMT solver_id={this.props.solver_id} />)
        } else {
            return (
                <div className="text-center">
                    <p>{t("general.missing_module_for_solver", {title: this.props.solver_id})}</p>
                    <p><Trans i18nKey="general.contact_with_email">Kontakt <a href={`mailto:${config.support_email}`}>{config.support_email}</a>.</Trans></p>
                </div>
            )
        }
    }
}

export const SolverDisplay = withTranslation()(SolverDisplay_)