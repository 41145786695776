import $ from "jquery"
import { v4 as uuidv4 } from "uuid"

import { CellData } from "../components/solverdisplay/commonTypes"
import i18n from "../i18n"
import { api } from "./API"

export function modalHack(modalID: string, show: boolean): string {
    if (show) {
        $(modalID).modal("show")
    }
    return ""
}

export function initTooltips(): string {
    $(document).ready(function () {
        $("[data-toggle=\"tooltip\"]").tooltip()
    })
    return ""
}

Number.isInteger =
    Number.isInteger ||
    function (value) {
        return (
            typeof value === "number" &&
            isFinite(value) &&
            Math.floor(value) === value
        )
    }

export function formatPrice(s: CellData): string {
    const price = typeof s == "number" ? s : parseFloat(s)
    if (i18n.language === "dk") {
        const locale = "da-DK"
        return Intl.NumberFormat(locale, {
            style: "currency",
            currency: "DKK",
            maximumFractionDigits: 2,
        }).format(price)
    } else {
        const locale = i18n.language
        return Intl.NumberFormat(locale, {
            style: "currency",
            currency: "DKK",
            maximumFractionDigits: 2,
        }).format(price)
    }

    // return s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function tryParseNumber(val: CellData): CellData {
    // Try to parse the entry as a number.
    // If the result is NaN, simply give back the string
    const parsed = Number(val)
    return isNaN(parsed) ? val : parsed
}

/**
 * @function parseStyles
 * Parses a string of inline styles into a javascript object with casing for react
 * https://gist.github.com/goldhand/70de06a3bdbdb51565878ad1ee37e92b
 *
 * @param {string} styles
 * @returns {Object}
 */
// const parseStyles = styles => styles
//     .split(';')
//     .filter(style => style.split(':')[0] && style.split(':')[1])
//     .map(style => [
//         style.split(':')[0].trim().replace(/-./g, c => c.substr(1).toUpperCase()),
//         style.split(':').slice(1).join(':').trim()
//     ])
//     .reduce((styleObj: any, style: any) => ({
//         ...styleObj,
//         [style[0]]: style[1],
// }), {});

// React.Component mixin:
// Represents a React Component that maintains active AJAX requests that needs to be aborted on unmount.
export interface ActiveAJAXManager {
    activeAJAX: JQueryXHR[]
}

export function activeAJAXManagerAdd(
    component: ActiveAJAXManager,
    ajax: JQueryXHR
): JQueryXHR {
    // Add the AJAX call to the list of active AJAX calls within the component.
    if (!component.activeAJAX) {
        component.activeAJAX = []
    }
    component.activeAJAX.push(ajax)
    return ajax
}

export function activeAJAXManagerUnmount(component: ActiveAJAXManager): void {
    if (!component.activeAJAX) {
        return
    }

    for (const xhr of component.activeAJAX) {
        xhr.abort()
    }
}

export function activeAJAXManagerList(component: ActiveAJAXManager): Array<JQueryXHR> {
    if (!component.activeAJAX) {
        return []
    }

    return component.activeAJAX
}

export interface advActiveAJAXManager {
    activeAJAX: Map<string, JQueryXHR>
}

export type CallType = "guest" | "user"

export function advAdd(
    component: advActiveAJAXManager,
    calltype: CallType,
    method: string,
    url: string,
    token?: string,
    settings?: JQuery.AjaxSettings
): JQueryXHR {
    // Require token if called with calltype user.
    if (calltype === "user" && !token) {
        throw Error("Token must be present when making API calls as user.")
    }

    // Initialize active AJAX map if needed
    if (!component.activeAJAX) {
        component.activeAJAX = new Map()
    }

    if (!settings) {
        settings = {}
    }

    const id = uuidv4()

    // Inject auto clear on complete behaviour
    // TODO: Test first case
    if (settings["complete"]) {
        const old_func = settings["complete"]
        settings["complete"] = (
            jqXHR: JQuery.jqXHR,
            statusText: JQuery.Ajax.TextStatus
        ) => {
            if (typeof old_func === "function") {
                old_func(jqXHR, statusText)
            } else {
                // old_func is old_funcs (array)
                old_func.forEach((func) => func(jqXHR, statusText))
            }
            component.activeAJAX.delete(id)
        }
    } else {
        settings["complete"] = () => {
            component.activeAJAX.delete(id)
        }
    }

    let call: JQueryXHR

    switch (calltype) {
    case "guest":
        call = api.callAsGuest(method, url, settings)
        break
    case "user":
        call = api.callWithToken(method, url, token as string, settings)
        break
    default:
        console.error("Something went wrong in active ajax management")
        throw Error(`Unknown API Calltype: ${calltype}`)
    }

    component.activeAJAX.set(id, call)
    return call
}

export function advUnmount(component: advActiveAJAXManager): void {
    if (!component.activeAJAX) {
        return
    }

    for (const xhr of component.activeAJAX.values()) {
        xhr.abort()
    }
}

export function advList(component: advActiveAJAXManager): IterableIterator<JQueryXHR> {
    if (!component.activeAJAX) {
        return [].values()
    }
    return component.activeAJAX.values()
}
