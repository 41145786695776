import React, { CSSProperties } from "react"

import classNames from "classnames"
import "./misc.css"

export type OpgoSpinnerProps = {
    style: CSSProperties
    progress: number | "spin"
}

export const OpgoSpinner: React.FunctionComponent<OpgoSpinnerProps> = ({ progress, style }) => {
    const computedStyle = React.useMemo((): CSSProperties => {
        if (progress === "spin") {
            return style
        }

        const degrees = Math.floor((progress % 1) * 360)

        return {
            ...style,
            transform: `rotate(${degrees}deg)`,
        }
    }, [style, progress])

    return (
        <img
            className={classNames({ "spin": progress === "spin" })}
            src="/assets/logo-symbol.svg"
            alt="spinner"
            style={computedStyle}
        />
    )
}
