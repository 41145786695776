import { config } from "./Config"



class Local {
    getObject(key: string, fallback: unknown) {
        if (typeof (Storage) !== "undefined") {
            const value = localStorage.getItem(key)
            return value ? (JSON.parse(value)) : (fallback)
        } else {
            return fallback
        }
    }

    storeObject(key: string, value: unknown) {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem(key, JSON.stringify(value))
        }
    }

    remove(key: string) {
        if (typeof (Storage) !== "undefined") {
            localStorage.removeItem(key)
        }
    }

    clear() {
        if (typeof (Storage) !== "undefined") {
            localStorage.clear()
        }
    }

    /*
     * Clear only non-protected keys.
     * Protected keys include keys set in the config.
     */
    reset() {
        const protected_keys: string[] = []

        for (const val of Object.values(config)) {
            if (typeof val === "string" && localStorage.getItem(val) !== null) {
                protected_keys.push(val)
            }
        }

        for (const key in localStorage) {
            if (!protected_keys.includes(key)) {
                localStorage.removeItem(key)
            }
        }
    }
}

const local = new Local()

export default local