import React, { Suspense } from "react"

import { FaChevronLeft } from "react-icons/fa"
import { Link, useParams } from "react-router-dom"

import { useAbortController, useAxiosClient } from "../utility/API"
import { FullpageSpinner } from "../utility/FullpageSpinner"
import { Solver } from "../utility/Solver"
import { UserContext } from "../utility/UserManager"

const ReactMarkdown = React.lazy(() => import("react-markdown"))

export const SolverInfo: React.FC = () => {
    const { solver_id } = useParams()

    const [solver, setSolver] = React.useState<Solver | undefined>(undefined)

    const context = React.useContext(UserContext)

    const abortController = useAbortController()
    const axiosClient = useAxiosClient({
        abortController: abortController,
        sessionToken: context.sessionToken,
    })

    // Get solver from id
    React.useEffect(() => {
        axiosClient
            .get(`/solvers/${solver_id}/`)
            .then((response) => {
                setSolver(response.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [axiosClient, abortController, solver_id, context.sessionToken])

    return solver ? (
        <div className="container">
            <div className="row">
                <div className="col-md-auto">
                    <br />
                    <br />
                    <Link to="/">
                        <button className="btn btn-secondary">
                            <FaChevronLeft />
                        </button>
                    </Link>
                </div>
                <div className="col">
                    <br />
                    <h1>{solver.title}</h1>
                    <br />
                    <Suspense fallback={<FullpageSpinner />}>
                        <ReactMarkdown>{solver.description}</ReactMarkdown>
                    </Suspense>
                </div>
            </div>
        </div>
    ) : (
        <meta className="WaitingForSolverID" />
    )
}

export default SolverInfo

// class _SolverInfo
//     extends React.Component<Props, State>
//     implements ActiveAJAXManager
// {
//     activeAJAX = []

//     constructor(props: Props) {
//         super(props)

//         this.state = {
//             solver: undefined,
//         }
//     }

//     static contextType = UserContext
//     context!: React.ContextType<typeof UserContext>

//     componentDidMount() {
//         activeAJAXManagerAdd(
//             this,
//             api.callWithToken(
//                 "GET",
//                 `/solvers/${this.props.solver_id}/`,
//                 this.context.sessionToken
//             )
//         )
//             .done((data) => {
//                 this.setState({
//                     solver: data,
//                 })
//             })
//             .fail((err) => {
//                 console.log(err)
//             })
//     }

//     componentWillUnmount() {
//         activeAJAXManagerUnmount(this)
//     }

//     render() {
//         if (!this.state.solver) {
//             return <FullpageSpinner />
//         }

//         const solver = this.state.solver

//         return (
//             <div className="container">
//                 <div className="row">
//                     <div className="col-md-auto">
//                         <br />
//                         <br />
//                         <Link to="/">
//                             <button className="btn btn-secondary">
//                                 <FaChevronLeft />
//                             </button>
//                         </Link>
//                     </div>
//                     <div className="col">
//                         <br />
//                         <h1>{solver.title}</h1>
//                         <br />
//                         <Suspense fallback={FullpageSpinner}>
//                             <ReactMarkdown>{solver.description}</ReactMarkdown>
//                         </Suspense>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

// export function SolverInfo(): JSX.Element {
//     const { solver_id } = useParams()

//     return (
//         (solver_id && <_SolverInfo solver_id={solver_id} />) || (
//             <meta className="WaitingForSolverID"></meta>
//         )
//     )
// }

// export default SolverInfo
