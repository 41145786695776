import React from "react"

import axios, {
    AxiosError,
    AxiosInstance,
    AxiosPromise,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosStatic,
} from "axios"
import axiosRetry, { IAxiosRetryConfig } from "axios-retry"
import $ from "jquery"

import { config as globalConfig } from "./Config"
import { Solver } from "./Solver"

type UUID = string

class API {
    callAsGuest(
        method: string,
        url: string,
        settings: JQuery.AjaxSettings = {}
    ) {
        const lang = localStorage.getItem("lang")

        const headers =
            lang !== null
                ? {
                    "Accept-Language": lang,
                }
                : {}

        return $.ajax(
            Object.assign(
                {
                    method: method,
                    url: globalConfig.backend + url,
                    headers: headers,
                },
                settings
            )
        )
    }

    callWithToken(
        method: string,
        url: string,
        token: string,
        settings: JQuery.AjaxSettings = {}
    ) {
        const headers: { [key: string]: string } = {}

        if (token) {
            headers["Authorization"] = `Token ${token}`
        } else {
            headers["Authorization"] = "Token session-token"
        }

        const lang = localStorage.getItem("lang")
        if (lang) {
            headers["Accept-Language"] = lang
        }

        return $.ajax(
            Object.assign(
                {
                    method: method,
                    url: globalConfig.backend + url,
                    headers: headers,
                },
                settings
            )
        )
    }

    getSolverData(solverId: string, token: string) {
        return api.callWithToken(
            "GET",
            `/solvers/${solverId}/`,
            token
        ) as JQuery.jqXHR<Solver>
    }

    callSolver(solverId: string, token: string, data: unknown) {
        return this.callWithToken("POST", `/solvers/${solverId}/solve`, token, {
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            timeout: 60000,
        })
    }

    flags() {
        return this.callAsGuest("GET", "/flags")
    }

    alive() {
        const data: { version?: string } = {}
        if (globalConfig.version && !globalConfig.version.includes("dev")) {
            // Only reveal our version if it has been specified
            // and we are not in development mode.
            data.version = globalConfig.version
        }
        return this.callAsGuest("GET", "/alive", {
            data: data,
        })
    }
}

export const api = new API()

type useAxiosClientParams = {
    abortController: AbortController
    timeout?: number
    sessionToken?: string
    retries?: number
    retryOptions?: IAxiosRetryConfig
}

/**
 * Shorthand for creating a new AxiosInstance with common settings.
 *
 * @param params  See IntelliSense for details.
 * @returns A new AxiosInstance with the given parameters.
 */
export const useAxiosClient: (params: useAxiosClientParams) => AxiosInstance = (
    params
) => {
    const { abortController, timeout, sessionToken, retries, retryOptions } =
        params

    const axiosInstance = React.useMemo<AxiosInstance>(
        () =>
            axios.create({
                baseURL: globalConfig.backend,
                headers: {
                    Authorization: sessionToken
                        ? `Token ${sessionToken}`
                        : "Token session-token",
                },
                timeout: timeout || 60000,
                signal: abortController.signal,
            }),
        [sessionToken, timeout, abortController.signal]
    )

    React.useEffect(() => {
        const _logger = axiosInstance.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                console.log(config)
                return config
            },
            (error) => Promise.reject(error)
        )
        return () => {
            axiosInstance.interceptors.request.eject(_logger)
        }
    }, [axiosInstance])

    // We could do useEffect here, but that would limit usage scenarios.
    // Also not clear if there would be duplicate interceptors.
    React.useEffect(() => {
        axiosRetry(axiosInstance, {
            retries: retries || 3,
            retryDelay:
                retryOptions?.retryDelay ||
                ((retryCount: number) => retryCount * 1000),
            ...retryOptions,
        })
    }, [axiosInstance, retryOptions, retries])

    return axiosInstance
}

/**
 * Creates and returns a new AbortController
 * that is automatically cleaned up when the component unmounts.
 */
export const useAbortController: () => AbortController = () => {
    const abortController = React.useMemo(() => new AbortController(), [])
    React.useEffect(() => {
        return () => {
            console.log("Aborting")
            abortController.abort()
        }
    }, [abortController])
    return abortController
}

//Async
const endpoints = {
    solve: (solverID: string) =>
        `${globalConfig.backend}/async/solvers/${solverID}/solve`,
    status: (taskID: UUID) =>
        `${globalConfig.backend}/async/tasks/${taskID}/status`,
}

const isRetryable = (error: AxiosError) => {
    return !!(
        error.response &&
        (error.response.status === 429 || error.response.status === 503) &&
        error.response.headers["retry-after"]
    )
}

const getRetryDelay = (error: AxiosError) => {
    // Should only be used when isRetryable succeeds.
    // Default to delay of 5
    return (
        (error.response && Number(error.response.headers["retry-after"])) || 5
    )
}

async function retry(
    axios: AxiosStatic | AxiosInstance,
    error: AxiosError,
    ms?: number
) {
    if (!error.config) {
        throw error
    } else {
        if (ms !== undefined) {
            await new Promise((resolve) => setTimeout(resolve, ms))
        }
        return axios(error.config)
    }
}

function getRetryCount(
    config: AxiosRequestConfig & Record<string, unknown>
): number {
    const key = "async-helper-retry-count"
    if (!config[key]) {
        config[key] = 0
        return 0
    } else if (typeof config[key] === "number") {
        return config[key] as number
    } else {
        config[key] = 0
        return 0
    }
}

function incrementRetryCount(
    config: AxiosRequestConfig & Record<string, unknown>
) {
    const key = "async-helper-retry-count"
    if (config[key] !== undefined) {
        config[key] = 1
    } else if (typeof config[key] === "number") {
        (config[key] as number) += 1
    } else {
        config[key] = 1
    }
}

export type SolveError = Record<string, unknown> & {
    error_code: "no-active-solver"
        | "no-access"
        | "missing-data"
        | "no-solver-connection"
        | "solver-not-ok"
        | "solver-not-json"
        | string
    // Is this ever set by a solver?
    result?: Record<string, unknown>
}

/**
 * Handles async API using functions only.
 *
 * @param solverID UUID as string of solver to use
 * @param data Data to include in initial post request for task creation
 * @param userToken User authorization token
 * @param abortController For aborting requests
 * @param config Optional request config
 * @param onTaskCreated Callback for task creation
 * @param onStatusUpdated Callback for status updates -- Note that status string may be JSON Encoded for some solvers.
 * @param onError Callback for errors
 * @param onResultReceived Callback for result ready
 */
export function asyncSolveHelper<Result = Record<string, unknown>>(
    solverID: UUID,
    data: unknown,
    userToken: string,
    abortController: AbortController,
    onTaskCreated?: (taskID: UUID) => void,
    onStatusUpdated?: (taskID: UUID, newStatus: string) => void,
    onResultReceived?: (taskID: UUID, result: Result) => void,
    onError?: (errorObj: AxiosError<SolveError>) => void,
    config?: AxiosRequestConfig<unknown>
): void {
    const headers: { [key: string]: string } = {}

    if (userToken) {
        headers["Authorization"] = `Token ${userToken}`
    } else {
        headers["Authorization"] = "Token session-token"
    }

    const lang = localStorage.getItem("lang")
    if (lang) {
        headers["Accept-Language"] = lang
    }

    // headers["Content-Type"] = "application/json"

    const instance = axios.create({
        timeout: 5000, // Async lets us use much lower timeouts -- Given in ms here.
        headers:
            (config && config.headers
                ? Object.assign(config.headers, headers)
                : headers) || headers,
        signal: abortController.signal,
    })

    // Handle automatic retry in case of rate limiting / backend not ready.
    // Inspired by axios-retry-after
    instance.interceptors.response.use(
        /* success interceptor -- no-op */
        (response) => {
            // console.log(response)
            return response
        },
        /* error interceptor -- retry when useful */
        (error: AxiosError<SolveError>) => {
            if (isRetryable(error)) {
                const d = getRetryDelay(error) * 1000
                console.log(`Retrying in ${d} ms`)
                return retry(instance, error, d)
            } else if (error.code === "ECONNABORTED") {
                // Likely that backend lost connection to rabbitmq completely
                return Promise.reject(error)
            } else if (
                Object.keys(error.response?.data || {}).includes("error_code")
            ) {
                // Likely slow db/rabbitmq access
                if (error.response?.data.error_code === "task_not_found") {
                    // The task has likely not yet been started.
                    // Might have been discarded, so we should retry solving from scratch after a few regular retries.
                    // Probably best to leave that up to the caller.
                    const retires = getRetryCount(
                        error.config as Record<string, unknown>
                    )
                    if (retires < 5) {
                        incrementRetryCount(
                            error.config as Record<string, unknown>
                        )
                        console.log(
                            `[${retires + 1} of ${5}] Retrying in ${2000} ms`
                        )
                        return retry(instance, error, 2000)
                    }
                    return Promise.reject(error)
                }
                return Promise.reject(error)
            } else {
                console.log("Could not retry based on response")
                console.log(error.response)
                return Promise.reject(error)
            }
        }
    )

    // Create the task & check for
    function solve() {
        instance
            .post(endpoints.solve(solverID), data)
            .then((response) => {
                // console.log(response)
                onTaskCreated && onTaskCreated(response.data["task_id"])

                // Get Task Updates
                const onSuccess = (res: AxiosResponse) => {
                    if (res === undefined) {
                        // Not quite sure when or why this happens...
                        return
                    }

                    if (
                        Object.prototype.hasOwnProperty.apply(res.data, [
                            "result",
                        ])
                    ) {
                        // Computation Finished
                        onResultReceived &&
                            onResultReceived(
                                response.data["task_id"],
                                res.data.result
                            )
                        return
                    }

                    onStatusUpdated &&
                        onStatusUpdated(
                            response.data["task_id"],
                            res.data.status
                        )

                    // We are still waiting for a result and possibly further status updates.
                    // Ensure that we don't make further calls if we should abort.
                    if (!abortController.signal.aborted) {
                        setTimeout(
                            () => {
                                instance
                                    .get(
                                        endpoints.status(
                                            response.data["task_id"]
                                        )
                                    )
                                    .then(onSuccess)
                                    .catch(onError || _onError)
                            },
                            globalConfig.stage === "production" ? 777 : 100
                        )
                    }
                }

                const _onError = (reason: AxiosError) => {
                    // Default error handler. Used only if not provided by caller

                    console.log(reason)
                    console.log(reason.response)
                    const s = reason.response?.status
                    console.log(`Request failed with status ${s}`)
                }

                instance
                    .get(endpoints.status(response.data["task_id"]))
                    .then(onSuccess)
                    .catch(onError || _onError)
            })
            .catch((reason) => {
                console.log(reason)
                onError && onError(reason)
            })
    }
    solve()
}

// Simple functions for create axios request functions
type OtherAxiosRequestOptions = Omit<AxiosRequestConfig, "baseUrl" | "url" | "method" | "data" | "signal"> & {
    urlParams?: Record<string, string>
}

export type AxiosRequestFunctionType<R, ID extends undefined | string> = (id: ID) => (abortController: AbortController, token: string) => AxiosPromise<R>
export function createAxiosRequest<R = unknown, ID extends undefined | string = undefined>(
    method: "GET" | "HEAD" | "DELETE",
    endpoint: string,
    options?: OtherAxiosRequestOptions
): AxiosRequestFunctionType<R, ID> {
    const config: AxiosRequestConfig = {
        ...options,
        baseURL: globalConfig.backend,
        method,
    }

    return (id) => {
        const endpointWithId = id == undefined ? endpoint : endpoint.replace(":id", id)
        return (abortController, token) => {
            return axios.request({
                ...config,
                url: endpointWithId,
                signal: abortController.signal,
                headers: {
                    Authorization: `Token ${token}`,
                },
            })}
    }
}
export type AxiosRequestFunctionWithBodyType<D, R, ID extends undefined | string> = (id: ID) => (abortController: AbortController, token: string, data: D) => AxiosPromise<R>
export function createAxiosRequestWithBody<D = unknown, R = unknown, ID extends undefined | string = undefined>(
    method: "POST" | "PUT" | "PATCH",
    endpoint: string,
    options?: OtherAxiosRequestOptions
): AxiosRequestFunctionWithBodyType<D, R, ID> {
    const config: AxiosRequestConfig = {
        ...options,
        baseURL: globalConfig.backend,
        method,
    }

    return (id) => {
        const endpointWithId = id == undefined ? endpoint : endpoint.replace(":id", id)
        return (abortController, token, data) => {
            return axios.request({
                ...config,
                url: endpointWithId,
                signal: abortController.signal,
                headers: {
                    ...config?.headers,
                    Authorization: `Token ${token}`,
                },
                data,
            })
        }
    }
}
