import "./index.scss"
import "bootstrap/dist/js/bootstrap.js"
import "./i18n"

import React from "react"
import * as ReactDOM from "react-dom"

import daLocale from "date-fns/locale/da"
import deLocale from "date-fns/locale/de"
import enLocale from "date-fns/locale/en-US"
import { ErrorBoundary } from "react-error-boundary"
import * as ReactGA from "react-ga"
import { Trans, useTranslation } from "react-i18next"

import {
    daDK as daDKCore,
    enUS as enUSCore,
    deDE as deDECore,
    Localization as LocalizationCore,
} from "@mui/material/locale"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import {
    daDK as daDKGrid,
    enUS as enUSGrid,
    deDE as deDEGrid,
} from "@mui/x-data-grid"
import { Localization as LocalizationGrid } from "@mui/x-data-grid/utils/getGridLocalization"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import App from "./App"
import registerServiceWorker, {
    unregister as unregisterServiceWorker,
} from "./registerServiceWorker"
import { config, check_config } from "./utility/Config"
import { FullpageSpinner } from "./utility/FullpageSpinner"

const localeMap: { [key: string]: string | Record<string, unknown> } = {
    ["da-DK"]: daLocale,
    ["de-DE"]: deLocale,
    ["en-GB"]: enLocale,
}

const muiLocaleMap: {
    [key: string]: ReadonlyArray<LocalizationCore | LocalizationGrid>
} = {
    ["da-DK"]: [daDKGrid, daDKCore],
    ["de-DE"]: [deDEGrid, deDECore],
    ["en-GB"]: [enUSGrid, enUSCore],
}

const localeAliasMap: { [key: string]: string } = {
    ["da-DK"]: "da",
    ["de-DE"]: "de",
    ["en-GB"]: "en",
}

const ErrorHandler: React.FunctionComponent = () => {
    const { t } = useTranslation()
    React.useEffect(() => {
        localStorage.clear()
    }, [])
    return (
        <div>
            <h1>{t("general.unknown_error")}</h1>
        </div>
    )
}

const Index: React.FunctionComponent = () => {
    const { t, i18n } = useTranslation()

    const theme = React.useMemo(
        () =>
            createTheme(
                {
                    palette: {
                        primary: {
                            main: "#5bb24c",
                        },
                        background: {
                            default: "#eaeaea",
                        },
                    },
                },
                ...(muiLocaleMap[i18n.language] ||
                    muiLocaleMap[localeAliasMap[i18n.language]] ||
                    muiLocaleMap["da-DK"])
            ),
        [i18n.language]
    )

    try {
        check_config()
    } catch (e) {
        console.error(e)

        const message = e instanceof Error ? e.message : (e as string)

        return (
            <div className="text-center">
                <p>{t("general.could_not_load_config_file")}</p>
                <p>
                    <code>{message}</code>
                </p>
                <p>
                    <Trans i18nKey="general.contact_with_email">
                        Kontakt{" "}
                        <a href={`mailto:${config.support_email}`}>
                            {config.support_email}
                        </a>
                        .
                    </Trans>
                </p>
            </div>
        )
    }

    // --- Config loaded, start web app ---

    if (config.google_analytics_id) {
        ReactGA.initialize(config.google_analytics_id)
    }

    console.log(i18n.languages, i18n.language)
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap[i18n.language]}
            >
                <ErrorBoundary FallbackComponent={ErrorHandler}>
                    <App />
                </ErrorBoundary>
            </LocalizationProvider>
        </ThemeProvider>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <React.Suspense fallback={<FullpageSpinner />}>
            <Index />
        </React.Suspense>
    </React.StrictMode>,
    document.getElementById("root") as HTMLElement
)

// Service worker
if (config.use_service_worker) {
    registerServiceWorker()
} else {
    unregisterServiceWorker()
}
