import * as React from "react"

import { Trans, withTranslation, WithTranslation } from "react-i18next"

import { config } from "./utility/Config"


class Help_ extends React.Component<WithTranslation> {

    render() {
        const { t } = this.props

        return (
            <div id="help-page" className="container">
                <h2>{t("general.contact")}</h2>
                <p>
                    <Trans i18nKey="help.if_questions_contact_us">
                        If you have any questions or wish to suggest improvements, do not hesitate to contact <a href={`mailto:${config.support_email}`}>{config.support_email}</a>.
                    </Trans>
                </p>

                <h2>{t("help.planned_features")}</h2>
                <ul className="list-group ">
                    <li className="list-group-item">{t("help.sort_columns_in_result")}</li>
                    <li className="list-group-item">{t("help.notification_bar_maintenance")}</li>
                    <li className="list-group-item">{t("help.automatic_change_email")}</li>
                    <li className="list-group-item">{t("help.automatic_reset_password")}</li>
                    <li className="list-group-item">{t("help.better_settings_layout")}</li>
                </ul>
            </div>
        )
    }
}

export const Help = withTranslation()(Help_)